import { useMemo, useEffect } from 'react';
import markerSDK from '@marker.io/browser';
import { useRouter } from 'next/router';

import { useUserData } from 'utils/hooks/useAuthenticatedUser';
import { sentryCaptureException } from 'utils/globals/sentry';
import { useDialogs } from 'utils/hooks/useDialogs';
import useAppContext from 'utils/hooks/useAppContext';

import { route } from 'constants/routes';
import { useNotificationPopup } from './useNotificationPopup';

export default function useMarkerIO(enable = false) {
  const user = useUserData();
  const { visitorId } = useAppContext();
  const router = useRouter();
  const {
    isArtistNoAccessModalOpen,
    isContactFormModalOpen,
    isAlreadyClaimedModal,
    isEditDisclaimerModal,
    isProfileAccessRequestsModalOpen,
    isRequestAccessModalOpen,
    isSwitchProfileModalOpen,
    isOnboardingModal,
    isOpenActionCenter,
    isLoginDialog,
  } = useDialogs();
  const { subscriptionNudgesPopup } = useNotificationPopup();

  const shouldHideWidget = useMemo(() => {
    const isRouteRestricted = [route.REGISTER, route.PAYMENT].some(r => router.route.includes(r));

    const dialogStates = {
      isArtistNoAccessModalOpen,
      isContactFormModalOpen,
      isAlreadyClaimedModal,
      isEditDisclaimerModal,
      isProfileAccessRequestsModalOpen,
      isRequestAccessModalOpen,
      isSwitchProfileModalOpen,
      isOnboardingModal,
      isOpenActionCenter,
      isLoginDialogOpen: isLoginDialog?.isOpen,
      isSubscriptionNudgesOpen: subscriptionNudgesPopup?.isOpen,
    };

    const isDialogOpen = Object.values(dialogStates).some(Boolean);

    return !enable || !process.env.MARKER_IO_DESTINATION_ID || isRouteRestricted || isDialogOpen;
  }, [
    enable,
    router.route,
    isArtistNoAccessModalOpen,
    isContactFormModalOpen,
    isAlreadyClaimedModal,
    isEditDisclaimerModal,
    isProfileAccessRequestsModalOpen,
    isRequestAccessModalOpen,
    isSwitchProfileModalOpen,
    isOnboardingModal,
    isOpenActionCenter,
    isLoginDialog?.isOpen,
    subscriptionNudgesPopup?.isOpen,
  ]);

  useEffect(() => {
    const initializeWidget = async () => {
      try {
        if (!window.Marker) {
          await markerSDK.loadWidget({
            destination: process.env.MARKER_IO_DESTINATION_ID,
            customData: {
              buildNumber: process.env.BUILD_NUMBER,
              visitorId,
            },
            reporter: user
              ? {
                  email: user.email,
                  fullName: user.name,
                }
              : undefined,
          });
        }
      } catch (error) {
        console.error('Marker.io initialization error:', error);
        sentryCaptureException(error);
      }
    };

    initializeWidget();
  }, [user, visitorId]);

  useEffect(() => {
    if (window.Marker) {
      try {
        if (shouldHideWidget) {
          window.Marker.hide();
        } else {
          window.Marker.show();
        }
      } catch (error) {
        console.error('Marker.io visibility toggle error:', error);
        sentryCaptureException(error);
      }
    }
  }, [shouldHideWidget]);
}

import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

const initialData = {
  preventPageScroll: false,
};

export const PageScrollContext = createContext(initialData);

const PageScrollProvider = props => {
  const [preventPageScroll, setPreventPageScroll] = useState(initialData.preventPageScroll);

  const router = useRouter();
  useEffect(() => {
    const handleRouteChangeComplete = () => {
      /* Avoid page scroll on first click
       if (!preventPageScroll) {
        window.scrollTo(0, 0);
      }
      */
      setPreventPageScroll(false);
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  const dataValue = useMemo(() => ({ preventPageScroll, setPreventPageScroll }), [preventPageScroll]);

  return <PageScrollContext.Provider value={dataValue} {...props} />;
};

export const usePageScroll = () => useContext(PageScrollContext);

export default PageScrollProvider;

import { createTheme } from '@mui/material/styles';
import { colors } from '../constants/styleConstants';

const v5Theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiButtonBase: { defaultProps: { disableRipple: true } },
    MenuItem: { defaultProps: { disableRipple: true, dense: true } },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '6px 12px',
        },
      },
      defaultProps: { selected: { color: colors.primary } },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': { backgroundColor: colors.background.gray },
          '&.Mui-checked.Mui-focusVisible': { backgroundColor: colors.blue3Opacity40 },
          '&.MuiCheckbox-colorSecondary.Mui-checked:hover': { backgroundColor: colors.blue3Opacity10 },
          '&.MuiCheckbox-colorSecondary:hover': { backgroundColor: colors.blue3Opacity10 },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': { backgroundColor: colors.background.gray },
          '&.Mui-checked.Mui-focusVisible': { backgroundColor: colors.blue3Opacity40 },
          '&.MuiRadio-colorPrimary.Mui-radio:hover': { backgroundColor: colors.blue3Opacity10 },
          '&.MuiRadio-colorPrimary:hover': { backgroundColor: colors.blue3Opacity10 },
          '&.MuiRadio-colorPrimary.Mui-checked': { color: colors.blue3 },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&.MuiAutocomplete-option.Mui-focused': { backgroundColor: colors.background.optionBg },
          '&.MuiAutocomplete-option.Mui-focused[aria-selected="true"]': { backgroundColor: colors.background.optionBg },
          '&.MuiAutocomplete-option[aria-selected="true"]': { backgroundColor: colors.background.gray },
          '&.MuiAutocomplete-option.Mui-focused:active': { backgroundColor: colors.background.optionBg },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiPaper: {
      styleOverrides: { root: { backgroundImage: 'unset' } },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.MuiMenuItem-root.Mui-selected': {
            backgroundColor: colors.background.gray,
          },
        },
      },
    },
  },
  palette: {
    text: { hint: colors.textGray },
    mode: 'light',
    type: 'light',
    primary: { main: colors.primary, contrastText: colors.white },
    secondary: { main: colors.blue3 },
    error: { main: colors.red },
    background: { default: 'rgb(255, 255, 255, 0)' },
    grey: { main: colors.grey15 },
  },
};

const theme = createTheme(v5Theme);

export default theme;
